<template>
    <div id="index">
        <Loading v-if="loading"></Loading>
        <template v-if="!loading">
            <AddAccount :visible="addShow" :editData="eidtData" @cancel="(value)=>{addShow=value}" @submit="GetAllUsers" v-if="addShow"></AddAccount>
            <a-row :gutter="20">
                <a-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
                    <div class="topbox csP" @click="$router.push('/home/user')">
                        <div class="iconWrap"><img src="../../public/image/admin.png" alt=""></div>
                        <div>
                            <p>账户</p>
                            <p class="num">{{usersList.length}}</p>
                        </div>
                    </div>
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
                    <div class="topbox bg2 csP">
                        <div class="iconWrap"><img src="../../public/image/cap.png" alt=""></div>
                        <div>
                            <p>总试卷/总题量</p>
                            <p class="num">5</p>
                        </div>
                    </div>
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
                    <div class="topbox bg3 csP">
                        <div class="iconWrap" style="margin-right:5px"><img src="../../public/image/house.png" alt=""></div>
                        <div>
                            <p>刷题人数/刷题总量</p>
                            <p class="num">5</p>
                        </div>
                    </div>
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
                    <div class="topbox bg4 csP">
                        <div class="iconWrap"><img src="../../public/image/book.png" alt=""></div>
                        <div>
                            <p>多平台统计</p>
                            <p class="num">5</p>
                        </div>
                    </div>
                </a-col>
            </a-row>   
            <a-row :gutter="20">
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="centerBox bg">
                        <div class="boxTitle">
                            <div><i class="point"></i>消息</div>
                            <div class="right csP">全部<a-icon type="right" class="icon"/></div>
                        </div>
                        <ul class="mesItem">
                            <li>
                                <div class="left">
                                    <div class="imgWrap"><img src="" alt=""></div>
                                    <div>
                                        <div class="name">张三</div>
                                        <div>工号：01245</div>
                                    </div>
                                </div>
                                <div class="right"><a-icon type="right" /></div>
                            </li>
                            <li>
                                <div class="left">
                                    <div class="imgWrap"><img src="" alt=""></div>
                                    <div>
                                        <div class="name">张三</div>
                                        <div>工号：01245</div>
                                    </div>
                                </div>
                                <div class="right"><a-icon type="right" /></div>
                            </li>
                            <li>
                                <div class="left">
                                    <div class="imgWrap"><img src="" alt=""></div>
                                    <div>
                                        <div class="name">张三</div>
                                        <div>工号：01245</div>
                                    </div>
                                </div>
                                <div class="right"><a-icon type="right" /></div>
                            </li>
                        </ul>
                    </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="centerBox">
                        <div class="boxTitle manage">
                            <div><i class="point"></i>员工管理</div>
                            <div class="right csP" @click="$router.push('/home/user')">全部<a-icon type="right" class="icon"/></div>
                        </div>
                        <ul class="mesItem manage" v-if="usersList.length">
                            <template v-for="( item, index ) in usersList" >
                                <li :key="index" v-if="index < 3">
                                    <div class="left">
                                        <div class="imgWrap"><img :src="item.headImg" alt="" v-if="item.headImg"></div>
                                        <div>
                                            <div class="name">{{item.nickname}}</div>
                                            <div>工号：{{item.no}}</div>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <icon-font type="icon-bianji" title="编辑" @click="addShow=true;eidtData=item"/>
                                        <!-- <a-icon type="delete" :class="{iconDisable:item.id==1}" @click="deleteUser(item)"/> -->
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <a-button class="btn border" style="height:50px" @click="addShow=true;eidtData={}"><a-icon type="plus"/>添加员工账号</a-button>
                    </div>
                </a-col>
            </a-row> 
        </template>
    </div>
</template>
<script>
import AddAccount from '@/components/addAccount'
import Loading from '@/components/loading'
import { getAllUsers, delUser } from '@/libs/api'
import { IconFont } from '@/libs/IconFont'

export default {
    name:'index',
    components:{ AddAccount, Loading, IconFont },
    data(){
        return{
            usersList: [],
            addShow: false,
            loading: true,
            eidtData: {}
        }
    },
    mounted(){
        this.GetAllUsers()
    },
    methods:{
        GetAllUsers(){
            this.loading = true
            getAllUsers({page:1,size:1000}).then(res=>{
                if(res){
                    let {count,list}=res
                    this.usersList=list
                }
                this.loading = false
            },error=>{
                console.log(error)
                this.loading = false
            })
        },
        deleteUser({id}){
            if(id == 1) return
            delUser({id}).then(res=>{
                this.GetAllUsers()
            },error=>{
                console.log(error)
            })
        },
        EditUser({id}){

        }
    }
}
</script>
<style lang="less" scoped>
.boxTitle{
    display: flex;
    padding: 22px 40px;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    .point{
        display: inline-block;
        width: 10px;
        height:10px;
        border-radius: 100%;
        background: #54dafe;
        vertical-align: middle;
        margin-right: 10px;
    }
    .right{
        font-size: 16px;
        .icon{
            margin-left: 8px;
            color: #d8d8d8
        }
    }
    &.manage{
        padding-top: 0;
    }
}
.mesItem{
    li{
        display: table;
        cursor: pointer;
        width: 100%;
        padding: 18px 40px;
        >*{
            display: table-cell;
            vertical-align: middle
        }
        .left{
            .imgWrap{
                display: inline-block;
                vertical-align: middle;
                width: 65px;
                height: 65px;
                border-radius: 100%;
                overflow: hidden;
                background: #fdece4
            }
            >div:last-child{
                font-size: 18px;
                padding-left: 20px;
                display: inline-block;
                vertical-align: middle;
                >div.name{
                    font-size: 16px;
                    color: #b7b4b4;
                    margin-bottom: 10px
                }
            }
        }
        .right{
            width: 50px;
            font-size: 20px;
            color: #6e8ae7;
            text-align: right
        }
    }
     &.manage{
        li{
            background: #fff;
            border-radius: 12px;
            margin-bottom: 10px;
            padding: 10px 30px;
            .left{
                .imgWrap{
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                >div:last-child{
                    font-size: 16px;
                    color: #999;
                    padding-left: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    >div.name{
                        font-size: 20px;
                        color: #333;
                        font-weight: 500;
                        margin-bottom: 10px
                    }
                }
            }
        }
    }
}
.btn.border{
    width: 100%;
}
.iconDisable{
    color: #ccc;
    cursor: no-drop
}
</style>